<template>
  <div class="login">
    <v-container>
      <v-row class="mt-10 flex-column align-center">
        <div>
          <v-col cols="12" sm="12" md="12">
            <v-img max-height="270" max-width="270" src="../assets/Mendoza-Fiduciaria-logo.png"></v-img>
            <h2 class="text-center">Crear nueva cuenta</h2>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="2" md="8" offset-sm="2" sm="8" offset-lg="4" lg="4">
          <section>
            <v-row>
              <v-col cols="6" align="right">
                <input type="radio" id="personaFisica" value="1" v-model="tipoCliente" checked />
                <label for="personaFisica">&nbsp;Persona f&iacute;sica</label>
              </v-col>
              <v-col cols="6" align="left">
                <input type="radio" id="empresa" value="0" v-model="tipoCliente" />
                <label for="empresa">&nbsp;Empresa</label>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="2" md="8" offset-sm="2" sm="8" offset-lg="4" lg="4">
          <v-form>
            <div v-if="tipoCliente === '1'">
              <v-text-field label="Nombre" v-model="nombre" v-on:keyup.enter="register"> </v-text-field>
              <v-text-field label="Apellido" v-model="apellido" v-on:keyup.enter="register"> </v-text-field>
              <v-text-field
                label="Número de CUIT"
                v-model="dni"
                v-on:keyup.enter="register"
                placeholder="Sin guiones"
                type="number"
                pattern="/^[0-9]{11}$/"
                :rules="[
                  (v) => /^[0-9]{11}$/.test(v) || 'Debe ingresar solo números',
                  (v) => v.length <= 11 || 'El CUIT no debe tener más de 11 caracteres',
                ]"
              >
              </v-text-field>
            </div>
            <div v-if="tipoCliente === '0'">
              <v-text-field label="Razón social" v-model="razonSocial" v-on:keyup.enter="register"> </v-text-field>
              <v-text-field
                label="Número de CUIT"
                v-model="cuit"
                v-on:keyup.enter="register"
                placeholder="Sin guiones"
                type="number"
                pattern="/^[0-9]{11}$/"
                :rules="[
                  (v) => /^[0-9]{11}$/.test(v) || 'Debe ingresar solo números',
                  (v) => v.length <= 11 || 'El CUIT/CUIL no debe tener más de 11 caracteres',
                ]"
              >
              </v-text-field>
            </div>
            <v-text-field
              label="Nro. Contacto:"
              v-model="telefono"
              v-on:keyup.enter="register"
              required
              type="number"
              pattern="/^[0-9]{11}$/"
              :rules="[
                (v) => /^[0-9]+$/.test(v) || 'El número de teléfono solo debe contener números',
                (v) => v.length >= 6 || 'El número de teléfono debe tener mas de  6 dígitos',
              ]"
            >
            </v-text-field>
            <v-autocomplete
              v-model="sectorProductivo"
              :items="sectorProductivos"
              filled
              required
              label="Sector productivo"
            ></v-autocomplete>
            <v-text-field label="Dirección de e-mail" v-model="email" v-on:keyup.enter="register"> </v-text-field>
            <v-text-field label="Contraseña" type="password" v-model="password" v-on:keyup.enter="register">
            </v-text-field>
            <div class="text-center">
              <v-btn color="success" @click="register"> Registrarse </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="3" md="6" offset-sm="2" sm="8">
          <v-alert :value="alert" color="#F29398" dark border="top" icon="mdi-cancel" transition="scale-transition">
            <v-row align="center">
              <v-col class="grow">{{ this.mensaje }}</v-col>
              <v-col class="shrink">
                <v-btn color="#EC6D75" @click="cerrarBoton">X</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Register',
  data: () => ({
    tipoCliente: '1',
    email: '',
    password: '',
    telefono: '',
    nombre: '',
    apellido: '',
    cuit: '',
    dni: '',
    razonSocial: '',
    sectorProductivo: '',
    sectorProductivos: [],
    mensaje: '',
    alert: false,
    text: '',
  }),

  methods: {
    clearPersonData() {
      this.nombre = '';
      this.apellido = '';
      this.dni = '';
    },
    clearCompanyData() {
      this.razonSocial = '';
      this.cuit = '';
    },
    register() {
      if (this.tipoCliente === '1') {
        if (!/^[0-9]{11}$/.test(this.dni)) {
          this.mensaje = 'El CUIT no tiene un formato válido.';
          this.alert = true;
          return;
        }
      }
      if (this.tipoCliente !== '1' && !/^[0-9]{11}$/.test(this.cuit)) {
        this.mensaje = 'El CUIT/CUIL no debe contener guiones y/o espacios.';
        this.alert = true;
        return;
      }
      // Basic phone number validation (optional)
      if (!this.telefono.trim() || this.telefono.length < 6) {
        this.mensaje = 'Debe ingresar un número de contacto válido.';
        this.alert = true;
        return;
      }

      axios
        .post(`${API_URL}api/usuarios`, {
          email: this.email,
          password: this.password,
          rol_id: 3, // CAMBIAR
          telefono: this.telefono,
          nombre: this.nombre,
          apellido: this.apellido,
          cuit: this.cuit,
          dni: this.dni,
          razonSocial: this.razonSocial,
          sectorProductivo: this.sectorProductivo,
          tipoCliente: this.tipoCliente,
        })
        .then(async (response) => {
          if (response.data.status === 'error') {
            this.mensaje = response.data.message;
            this.alert = true;
          } else {
            this.$swal
              .fire({
                title: 'Usuario Registrado',
                text: `Cliente_Id: ${response.data.cliente_id}, revise su mail para comenzar a operar`,
                showDenyButton: false,
                icon: 'success',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#78B04E',
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  localStorage.setItem('email', this.email);
                  this.mensaje = 'Usuario registrado';
                  this.$router.push('/');
                }
              });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: 'Error',
            text: 'Ocurrió un error al generar el usuario',
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f32',
          });
          this.mensaje = 'Hubo un problema al registrar el usuario';
          this.alert = true;
        });
    },
    cerrarBoton() {
      this.alert = false;
    },
    login() {
      this.$router.push('/login');
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            this.$router.push('/');
          });
      }
    },
    async loadSectorProductivos() {
      const url = `${API_URL}api/sectoresProductivos`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.sectorProductivos = response.data.sectoresProductivos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    tipoCliente(newVal, oldVal) {
      if (newVal === '1' && oldVal === '0') {
        this.clearCompanyData();
      } else if (newVal === '0' && oldVal === '1') {
        this.clearPersonData();
      }
    },
  },
  created() {
    this.redireccion();
    this.loadSectorProductivos();
  },
};
</script>
